<template>
    <div class="toasts-container">

        <div v-for="(message) in messages"
             :key="message.id"
             class="toast show align-items-center border-0 mt-2"
             :class="{ 'text-bg-primary':message.type === 'info', 'text-bg-success':message.type === 'success', 'text-bg-danger':message.type === 'error' }">
            <div class="d-flex">
                <div class="toast-body">
                    <component v-if="message.component" :is="message.component" v-bind="message.attributes || {}" :close="() => removeMessage(message)"/>
                    <template v-else>
                        <strong v-if="message.title && message.body">{{message.title}}</strong>
                        <div>
                            {{message.body || message.title}}
                        </div>
                    </template>
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" @click="removeMessage(message)"></button>
            </div>
        </div>

    </div>
</template>

<script setup>
    import backend from '#main/backend';
    import notify from './service';
    import { ref } from 'vue';

    const messages = ref([]);

    function addMessage(message){
        messages.value.push(message);
    }

    function removeMessage(message){
        let msgIndex = messages.value.indexOf(message);
        if(msgIndex > -1) messages.value.splice(msgIndex, 1);
    }

    // implement notification showing
    notify.show = (type, title = '', body = '', timeout = 6000, component, attributes) => {

        if(timeout !== false) {
            if(type === 'info') timeout = 6000;
            else if(type === 'success') timeout = 6000;
            else if(type === 'error') timeout = 10000;
            else throw new Error('Notification type "'+type+'" not recognized');
        }

        let id = new Date().getTime() + Math.round(Math.random() * 100);
        let message = { id, type, title, body, component, attributes };

        addMessage(message);
        if(timeout !== false) setTimeout(() => removeMessage(message), timeout);
    };

    // handle global http error warnings
    backend.onLoadingError(err => {
        notify.error('Loading failed.', 'Please refresh page and try again.');
    });
</script>

<style scoped>
    .toasts-container {
        z-index:9999;
        position:fixed;
        top:0;
        right:5vw;
        width:90vw;
        max-width:500px;
    }

    .toasts-container .toast {
        width:100%;
    }
</style>