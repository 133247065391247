import guid from '#utils/guid.js';
import UAParser from 'ua-parser-js';

const analytics = {
    disabled: false,
    disable(){
        this.disabled = true;
    },

    install(app, options) {
        if(this.disabled) return;

        // global error catcher
        window.addEventListener('error', function(e) {
            analytics.trackError(e.error);
        });
        
        app.config.errorHandler = (error) => {
            this.trackError(error);

            console.error(error);
        };

        app.config.warnHandler = (msg, instance, trace) => {
            let error = new Error(msg);
            error.stack = trace;
            this.trackError(error);

            console.error(msg, instance, trace);
        };
    },
    
    get sessionId(){
        if(this.disabled) return;

        let sId = window.localStorage.getItem('secTokenId');
        if(!sId) {
            sId = guid() + '____' + window.btoa(encodeURIComponent(window.Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone));
            localStorage.setItem('secTokenId', sId);
        }

        return sId;
    },
    trackEvent(eventName, eventData){
        if(this.disabled) return;

        if(eventName === 'pageview' || eventName === 'error') {
            eventData = { ...eventData, url:location.href, referrer:document.referrer, screen:{ w:window.screen.width, h:window.screen.height }, window:{ w:window.innerWidth, h:window.innerHeight } };

            let ua = new UAParser();
            let os = ua.getOS() || {};
            let browser = ua.getBrowser() || {};
            eventData.os = os.name+' '+os.version;
            eventData.browser = browser.name+' '+browser.version;
        }
    
        function encode(d){
            return window.btoa(encodeURIComponent(JSON.stringify(eventData))).replace(/\=/g,'&').replace(/\+/g,'*').split('M').reverse().join('M');
        }
    
        // window.fetch('/sec-check', { method:'POST', body:encode({ type:eventName, data: eventData, sId: this.sessionId }) }).catch(err=>{});
    },

    maxErrorCount: 3,
    trackError(error){
        if(this.maxErrorCount <= 0) return;
        this.maxErrorCount--;
        this.trackEvent('error', { message:error?.message, stack:error?.stack });
    }
};

export default analytics;