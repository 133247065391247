export default {
    format:{
        dateTime(date){
            return date ? new Date(date).toLocaleString('de-DE') : '';
        },
        date(date){
            return date ? new Date(date).toLocaleDateString('de-DE') : '';
        },
        time(date){
            return date ? new Date(date).toLocaleTimeString('de-DE') : '';
        },
        timeAgo(date) {
            const currentDate = new Date();
            const past = new Date(date);
            const msPerMinute = 60 * 1000;
            const msPerHour = msPerMinute * 60;
            const msPerDay = msPerHour * 24;
            const msPerMonth = msPerDay * 30;
            const msPerYear = msPerDay * 365;
        
            const elapsed = currentDate - past;
        
            if (elapsed < msPerMinute) {
                return Math.round(elapsed / 1000) + ' seconds ago';
            } else if (elapsed < msPerHour) {
                return Math.round(elapsed / msPerMinute) + ' minutes ago';
            } else if (elapsed < msPerDay ) {
                return Math.round(elapsed / msPerHour ) + ' hours ago';
            } else if (elapsed < msPerMonth) {
                const days = Math.round(elapsed / msPerDay);
                return days === 1 ? 'yesterday' : days + ' days ago';
            } else if (elapsed < msPerYear) {
                return Math.round(elapsed / msPerMonth) + ' months ago';
            } else {
                return Math.round(elapsed / msPerYear ) + ' years ago';
            }
        },

        price(number){
            return number + ' €';
        },
    },
    hello:'hello'
};