import locales from './locales/index.js';

export let currentLang = '';

export const translate = (key) => {
    return locales[currentLang][key] ?? key;
};

export const setLanguage = (lang) => {
    currentLang = lang;
    translate.format = locales[currentLang].format;
};

setLanguage('en');

export const i18nPlugin = {
    install(app, options) {
        app.config.globalProperties.$setLanguage = setLanguage;
        app.config.globalProperties.$t = translate;
    }
};