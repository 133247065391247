<template>
    <footer class="py-5">
        <div class="container border-top pt-4">
            <div class="row row-cols-1 row-cols-lg-3 text-center">
                <div class="col text-lg-start">
                    <div>
                        <img src="/images/logo/mechflows.svg" style="width: 30px;height: 100%;">
                        <span class="fw-bold text-dark ms-2">Mechflows</span>
                    </div>
                    <p class="text-muted my-2 small">Copyright&nbsp;© 2024</p>
                </div>
                <div class="col">
                    <ul class="list-inline my-2">
                        <li class="list-inline-item">
                            <!-- social icons -->
                        </li>
                    </ul>
                </div>
                <div class="col text-lg-end">
                    <ul class="list-inline my-2 small">
                        <li class="list-inline-item"><a class="link-secondary" href="/privacy-policy">Privacy Policy</a></li>
                        <li class="list-inline-item"><a class="link-secondary" href="/terms-of-use">Terms of Use</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>

</script>