import router from '../../main/router.js';
import store from './store.js';
import Page from './Page.vue';

router.addRoute({ name:'home', path: '/', component: Page });

// // load and cache experts before home route
// router.beforeEach(async (to, from) => {
//     if(to.name === 'home') store.loadExperts();
//     return true;
// });