import { createApp } from 'vue';
import { i18nPlugin } from '#modules/i18n/index.js';
import backend from './backend.js';
import router from './router.js';
import App from './App.vue';
import '#ui-base'; // include globals
import '#sections/routes.js'; // register routing
import analytics from '#modules/analytics.js';

const app = createApp(App);

if(process.env.NODE_ENV === 'production') {
    app.use(analytics); // only use analytics in production

    // auto track pageview
    router.afterEach((to, from) => {
        analytics.trackEvent('pageview');
    });

    // add to sessionId to all headers
    backend.sessionId = analytics.sessionId;
}

app.use(i18nPlugin);
app.use(router);

app.mount('#app');