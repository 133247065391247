<template>
    <div>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-10 col-xl-8 col-xxl-7 mx-auto my-5">
                        <h1>Privacy Policy</h1>
                        <p>Effective: August, 2024<br><br>This Privacy Policy explains how we collect, use, and share
                            information about you when you use our website Mechflows.com (the “Site”). We understand
                            that your privacy is important to you, and we are committed to being transparent about the
                            information we collect and how we use it.<br><br><strong>Information We
                                Collect</strong><br><br>We collect information about you when you use our Site. This
                            information may include:<br><br>- Email: We collect your email address when you provide it
                            to us.<br>- Usage Information: We collect information about how you use our Site, such as
                            the pages you visit and the links you click. We may also collect information about your
                            device and browser, such as your IP address, device type, and operating system.<br>- Cookies
                            and Similar Technologies: We use cookies and similar technologies to collect information
                            about your use of our Site. Cookies are small data files that are placed on your device when
                            you visit a website. They allow us to remember your preferences and personalize your
                            experience on our Site.<br><br><strong>How We Use Your Information</strong><br><br>We use
                            the information we collect to provide and improve our Site, to communicate with you, and to
                            personalize your experience on our Site. We may also use your information to:<br><br>-
                            Respond to your inquiries and requests.<br>- Send you marketing communications.<br>- Analyze
                            and improve our Site.<br>- Comply with legal obligations.<br><br><strong>How We Share Your
                                Information</strong><br><br>We may share your information with third-party service
                            providers who perform services on our behalf, such as website hosting, data analysis,
                            payment processing, order fulfillment, and customer service. We may also share your
                            information with our affiliates and business partners for marketing
                            purposes.<br><br><strong>Your Choices</strong><br><br>You can choose not to provide personal
                            information to us, but this may limit your ability to use certain features of our Site. You
                            can also opt out of receiving marketing communications from us by following the instructions
                            in the communication.<br><br><strong>Data Security</strong><br><br>We take reasonable
                            measures to protect your information from unauthorized access, use, or disclosure. However,
                            no method of transmission over the Internet or method of electronic storage is 100%
                            secure.<br><br><strong>Changes to This Privacy Policy</strong><br><br>We may update this
                            Privacy Policy from time to time. If we make material changes to this Privacy Policy, we
                            will notify you by email or by posting a notice on our Site prior to the effective date of
                            the changes.<br><br><strong>Contact Us</strong><br><br>If you have any questions or concerns
                            about this Privacy Policy, please contact us at info@mechflows.com .</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>