import { reactive } from 'vue';
import backend from './backend.js';
import router from './router.js';

const store = reactive({
    isLoading: false,

    user:{
        id: null,
        email:'',
        roles:[],
        isAdmin: false,
        isResolved: false,
        isLoggedIn: false,
        credits: 0
    },
    setUser(user){
        this.user.id = user?.id;
        this.user.email = user?.email;
        this.user.roles = user?.roles;
        this.user.credits = user?.credits;
        this.user.isLoggedIn = !!user?.id;
        this.user.isResolved = true;
        this.user.isAdmin = user?.roles?.includes('admin') || false;

        if(user) this.loadUserFlows();
    },

    menu:{
        mobileItems:{},
        activeMobileItem: null
    },
    setMobileMenuItems(activeItemName, itemsObj){
        this.menu.mobileItems = { menu:'Menu', ...itemsObj };
        this.menu.activeMobileItem = activeItemName;

        // TODO:
        // reset state after route to avoid out of sync menu items
        // router.afterEach((to, from) => {})
    },
    setMobileActiveItem(itemName){
        this.menu.activeMobileItem = itemName;
    },

    flows:{
        selectedId: null,
        hasNext: false,
        list:[],
        page: 1,
        byParentId:{}
    },

    async createNewFlow({ name }){
        const { status, body } = await backend.post('/flows').body({ name }).send();
        this.flows.list.unshift(body);
        router.push({ name: 'flow', params:{ flowId:body.id } });
    },

    async loadUserFlows(page = this.flows.page){
        const { status, body } = await backend.get('/flows').query({ page }).send();
        this.flows.list = this.flows.list.concat(body.flows);
        this.flows.hasNext = body.hasNext;
        this.flows.page = page;
    },

    async loadUserFlowsNext(){
        return this.loadUserFlows(this.flows.page + 1);
    },

    async renameFlow({ flowId, name }){
        const { status, body } = await backend.put('/flows/' + flowId + '/name').body({ name }).send();
        const flow = this.flows.list.find(flow => flow.id === flowId);
        if(flow) flow.name = body.name;
    },

    async deleteFlow({ flowId }){
        const { status, body } = await backend.delete('/flows/' + flowId).send();
        this.flows.list = this.flows.list.filter(flow => flow.id !== flowId);
        if(router.currentRoute.value.name && router.currentRoute.value.params.flowId === flowId) {
            router.push({ name: 'home' });
        }
    }

    // loadMenuSubItems(parentItem, page = 1){
    //     backend.get('/flows/?parentId='+ encodeURIComponent(parentItem.id) + '&page='+page, (status, body) => {
    //         parentItem.children = parentItem.children || [];
    //         parentItem.children = parentItem.children.concat(body.flows);
    //         parentItem.hasNext = body.hasNext;
    //     });
    // },
});

// reset mobile menu before each page to avoid non-actual navigation items
router.beforeEach(async (to, from) => {
    store.setMobileMenuItems('page', {
        menu:'Menu',
        page:'Page'
    });
    
    return true;
});

// register backend loading state
backend.onLoadingChange((isLoading) => {
    store.isLoading = isLoading;
});

export default store;