import config from './config.js';
import { HttpService } from '#modules/HttpService.js';

class Backend extends HttpService {

    // use CRUD prefixes, e.g.:
    // getResources(){} // get
    // getResourceDetails(){} // get single
    // createResource(){} // post
    // updateResource(){} // put
    // saveResource(){} // patch
    // deleteResource(){} // delete

    // AUTH
    getUser(){
        return this.get('/user').send();
    }
    loginUser(email, password){
        return this.post('/login').body({ email, password }).send();
    }
    async logoutUser(){
        await this.get('/logout').send();
        location.href = config.backendBasePath;
    }
    requesUserPasswordReset(email){
        return this.post('/forgotpass').body({ email }).send();
    }
    registerUser(email, password){
        return this.post('/register').body({ email, password }).send();
    }
    changeUserPassword(email, token, oldPass, newPass){
        return this.post('/changepass').body({ email, token, oldPass, newPass }).send();
    }

    // // SCENARIOS
    // getJobDescriptions(query = {}){
    //     return this.post('/job_descriptions').body(query).send()
    // }
    // getJobDescriptionDetail(id){
    //     return this.get('/job_descriptions/'+id).send();
    // }
    // updateJobDescription(jobDescription){
    //     return this.put('/job_descriptions').body(jobDescription).send();
    // }
}

export default new Backend({ basePath: config.backendBasePath });