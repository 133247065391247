<template>
    <div class="container py-5">
        <div class="row">
            <div class="col-md-8 col-xl-6 text-center mx-auto">
                <h3 class="fw-bold">Page Not Foud (404)</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 col-lg-6 col-xl-4 mx-auto mt-2">
                <div class="p-2">
                    <RouterLink :to="{ name:'home' }" class="btn btn-outline-dark d-block w-100 mt-3">
                        <i class="bi bi-house me-2"></i>
                        Go To Home Page
                    </RouterLink>
                </div>
            </div>
        </div>
    </div>
</template>