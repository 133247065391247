import { reactive } from 'vue';
import backend from '#main/backend.js';

const store = reactive({
    experts:[],
    async loadExperts(forceRefresh){
        if(this.experts.length > 0 && !forceRefresh) return;

        const { status, body } = await backend.get('/experts').send();
        this.experts = body.experts;
    }
});

export default store;