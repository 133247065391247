import { markRaw } from 'vue';

export default {
    show(type, title, body, timeout, Component, attributes){
        if(['info', 'success', 'error'].indexOf(type) === -1) throw new Error('Unrecognized type of notification, allowed ony "info", "success", or "error"');
        alert('NOTIFICATION ' + type + ' title:' + title + ' body:' + body);
    },
    info(title, body, timeout){
        this.show('info', title, body, timeout);
    },
    success(title, body, timeout){
        this.show('success', title, body, timeout);
    },
    error(title, body, timeout){
        this.show('error', title, body, timeout);
    },
    custom(type, Component, attributes, timeout) {
        this.show(type, null, null, timeout, markRaw(Component), attributes);
    }
};
