<template>
    <div>
        <section>
        <div class="container">
            <div class="row">
                <div class="col-md-10 col-xl-8 col-xxl-7 mx-auto my-5">
                    <h1>Terms of Use</h1>
                    <p>Effective: August, 2024<br><br>Welcome to Mechflows.com. These terms of use (“Terms”) govern
                        your use of our website and any related services (collectively, the “Service”). By using the
                        Service, you agree to be bound by these Terms.<br><br><strong>Use of the
                            Service</strong><br><br>You may use the Service only for lawful purposes and in accordance
                        with these Terms. You agree not to use the Service:<br><br>- In any way that violates any
                        applicable federal, state, local, or international law or regulation.<br>- To engage in any
                        conduct that restricts or inhibits anyone’s use or enjoyment of the Service, or which, as
                        determined by us, may harm Mechflows.com or users of the Service or expose them to
                        liability.<br>- To impersonate or attempt to impersonate Mechflows.com, a Mechflows.com
                        employee, another user, or any other person or entity.<br>- To engage in any other conduct that
                        restricts or inhibits anyone’s use or enjoyment of the Service, or which, as determined by us,
                        may harm Mechflows.com or users of the Service or expose them to
                        liability.<br><br><strong>Disclaimer of Warranties</strong><br><br>The Service is provided on an
                        “as is” and “as available” basis. Mechflows.com makes no representations or warranties of any
                        kind, express or implied, as to the operation of the Service or the information, content,
                        materials, or products included on the Service. You expressly agree that your use of the Service
                        is at your sole risk.<br><br><strong>Limitation of Liability</strong><br><br>In no event shall
                        Mechflows.com be liable for any direct, indirect, incidental, special, or consequential damages
                        arising out of or in any way connected with the use of the Service or with the delay or
                        inability to use the Service, or for any information, products, and services obtained through
                        the Service, or otherwise arising out of the use of the Service, whether based on contract,
                        tort, strict liability, or otherwise, even if Mechflows.com has been advised of the possibility
                        of damages.<br><br><strong>Indemnification</strong><br><br>You agree to indemnify, defend, and
                        hold harmless Mechflows.com, its affiliates, officers, directors, employees, agents, licensors,
                        and suppliers from and against all claims, losses, expenses, damages, and costs, including
                        reasonable attorneys’ fees, arising out of or in connection with your use of the Service or your
                        violation of these Terms.<br><br><strong>Changes to These Terms</strong><br><br>We reserve the
                        right to modify these Terms at any time. If we make material changes to these Terms, we will
                        notify you by email or by posting a notice on the Service prior to the effective date of the
                        changes. Your continued use of the Service after the effective date of any such changes
                        constitutes your acceptance of the new Terms.<br><br><strong>Contact Us</strong><br><br>If you
                        have any questions or concerns about these Terms, please contact us at
                        info@mechflows.com .</p>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>