<template>
    <nav class="navbar navbar-expand-md bg-body py-3">
        <div class="container">
            <a class="navbar-brand d-flex align-items-center" href="/">
                <img src="/images/logo/mechflows.svg" style="width: 30px;height: 100%;">
                <span class="fw-bold ms-2">Mechflows</span>
            </a>
            <button class="navbar-toggler" @click="isOpened = !isOpened">
                <span class="visually-hidden">Toggle navigation</span><span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse fs-6" :class="{ show:isOpened }">
                <ul class="navbar-nav me-auto">
                    <li class="nav-item"><a class="nav-link" href="/privacy-policy">Privacy Policy</a></li>
                    <li class="nav-item"><a class="nav-link" href="/terms-of-use">Terms Of Use</a></li>
                </ul>
                <a class="me-4" href="/app/login">Log in</a>
                <a class="btn btn-primary bg-gradient" href="/app/register">Start for free<i class="bi bi-arrow-right ms-1"></i></a>
            </div>
        </div>
    </nav>
</template>

<script setup>
    import { ref } from "vue";

    const isOpened = ref(false);
</script>