export class HttpService {

    constructor({ basePath, baseUrl }){
        if(baseUrl) this.baseUrl = baseUrl;
        this.basePath = basePath;
    }

    fetchClient(){ return fetch(...arguments); }

    baseUrl = '';
    basePath = '/';
    sessionId = null;

    activeRequests = 0;
    throttleTime = 120;
    dispatchTimeout = null;
    loadingStateListeners = [];

    loadingStateChange(activeRequestsInc){
        this.activeRequests += activeRequestsInc;
        this.dispatchLoadingChange();
    }

    onLoadingChange(cb){
        cb(!!this.activeRequests);
        this.loadingStateListeners.push(cb);
    }

    dispatchLoadingChange(){
        if(this.dispatchTimeout) return;

        this.dispatchTimeout = setTimeout(() => {
            this.loadingStateListeners.forEach(fnc => fnc(!!this.activeRequests));
            this.dispatchTimeout = null;
        }, this.throttleTime);
    }

    errorListeners = [];
    onLoadingError(cb){
        this.errorListeners.push(cb);
    }

    dispatchLoadingError(err){
        this.errorListeners.forEach(fnc => fnc(err));
    }

    requestListeners = [];
    onRequest(cb){
        this.requestListeners.push(cb);
    }

    dispatchRequest(err){
        this.requestListeners.forEach(fnc => fnc(err));
    }

    responseListeners = [];
    onResponse(cb){
        this.responseListeners.push(cb);
    }
    dispatchResponse(err){
        for(const fnc of this.responseListeners){
            const result = fnc(err);
            if(result) return result;
        }
    }

    createUrl(path, query = {}){
        query = query || {};
        if(path.indexOf('//') === 0) path = path.slice(1);
        else path = this.baseUrl + this.basePath + path;
        let queryString = new URLSearchParams(query).toString();
        return path + (queryString ? '?' + queryString : '');
    }

    fetch(method, path, query, body, cb){

        let headers = { 'Content-Type': 'application/json', 'x-requested-with':'XMLHttpRequest', 'session-id':this.sessionId };
        this.dispatchRequest({ method, path, query, body, headers });

        let url = this.createUrl(path, query);
        this.loadingStateChange(1);

        return this.fetchClient(url, { method, body: body ? JSON.stringify(body) : undefined, headers })
            .then((response) => {
                return new Promise(resolve => {
                    response.text()
                        .then(body => {
                            try { body = parseDatesInObject(JSON.parse(body)); }
                            catch(err){}
                            resolve({ status:response.status, body });
                        });
                });
            })
            .catch(err => {
                this.dispatchLoadingError(err);
                throw err;
            })
            .then(({ status, body }) => {
                const interceptReturn = this.dispatchResponse({ status, body });
                if(interceptReturn) return interceptReturn;

                // default fallback for server error
                if(status === 500) {
                    this.dispatchLoadingError('Loading Data Failed');
                    return Promise.reject('Fetching "'+url+'" failed'); // TODO: This should be logged
                }

                if(cb) cb(status, body);
                return { status, body };
            })
            .finally((a,b) => {
                this.loadingStateChange(-1);
            });
    }
    request(method, path){
        return new HttpRequest({
            method,
            path,
            send:(req) => (cb) => this.fetch(method, req._path, req._query, req._body, cb)
        });
    }
    get(path){
        return this.request('GET', path);
    }
    head(path){
        return this.request('HEAD', path);
    }
    post(path){
        return this.request('POST', path);
    }
    put(path){
        return this.request('PUT', path);
    }
    patch(path){
        return this.request('PATCH', path);
    }
    delete(path){
        return this.request('DELETE', path);
    }

    getChunkedWhile(path, whileCb, repeatNo = 0, requestAPI = {}){
        if(repeatNo >= 5) return;

        let url = this.createUrl(path, {});
        makeRequest();

        function makeRequest(repeat = 0){
            if(repeat > 5) return;

            const eventSource = new EventSource(url);
            
            function abort(){
                eventSource.aborted = true;
                eventSource.close();
            }

            requestAPI.abort = abort;

            let whileConditionSatisfied = false;

            // Listen for events
            eventSource.addEventListener('message', event => {
                if(whileConditionSatisfied) return abort(); // condition was satisfied, no need to pull again

                let data;
                try {
                    data = JSON.parse(event.data + '');
                    whileConditionSatisfied = whileCb(null, data);
                }
                catch(err){}
                
                if(whileConditionSatisfied) return abort(); // condition was satisfied, no need to pull again
            });

            // Listen for errors and log connection status
            eventSource.addEventListener('error', event => {
                if(whileConditionSatisfied) abort(); // condition was satisfied, no need to pull again

                if (eventSource.readyState === 0) {
                    // Reconnecting...
                }
                else if (eventSource.readyState === EventSource.CLOSED) {
                    // eventSource.close();
                }
                else {
                    // auto reconnect, not needed to makeRequest manually
                    // if(!whileConditionSatisfied) setTimeout(() => makeRequest(repeat+1)); // repeat request
                }
            });
        }

        return requestAPI;
    }
};

function parseDatesInObject(obj) {
    if(!obj) return obj;

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'string' && isISO8601Date(obj[key])) {
                obj[key] = new Date(obj[key]);
            } else if (typeof obj[key] === 'object') {
                parseDatesInObject(obj[key]); // Recursive call for nested objects
            }
        }
    }

    return obj;
}

function isISO8601Date(str) {
    // const regexIso8601 = /^(\d{4}|\+\d{6})(?:-(\d{2})(?:-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})\.(\d{1,})(Z|([\-+])(\d{2}):(\d{2}))?)?)?)?$/;
    // const regexIsoStrict = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z?$/;
    return regex.test(str);
}

export class HttpRequest {
    availableMethods = ['GET','HEAD','POST','PUT','PATCH','DELETE'];
    _method = 'GET';
    method(methodName){
        methodName = methodName.toUpperCase();
        if(!this.availableMethods.includes(methodName)) throw new Error('Request method "'+methodName+'" not recognized');
        this._method = methodName;
        return this;
    }
    
    _path = '';
    path(path){
        this._path = path;
        return this;
    }

    _query = {};
    query(query){
        this._query = query;
        return this;
    }

    _body = undefined;
    body(body){
        this._body = body;
        return this;
    }

    // _headers = {};
    // headers(headers){
    //     this._headers = headers;
    //     return this;
    // }

    constructor({ method, path, query, body, send }){
        this._method = method;
        this._path = path;
        this._query = query;
        this._body = body;
        this._send = send(this);
    }

    get(path){
        return this.method('GET').path(path);
    }
    head(path){
        return this.method('HEAD').path(path);
    }
    post(path){
        return this.method('POST').path(path);
    }
    put(path){
        return this.method('PUT').path(path);
    }
    patch(path){
        return this.method('PATCH').path(path);
    }
    delete(path){
        return this.method('DELETE').path(path);
    }

    _send = (cb) => new Promise();
    send(cb){
        return this._send(cb);
    }
}