import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router';
import config from './config.js';

const isServer = typeof window === 'undefined';

const router = createRouter({
    routes:[], // routes are imported in app.js
    history: isServer ? createMemoryHistory(config.appBasePath) : createWebHistory(config.appBasePath),
    linkExactActiveClass: 'active',
    linkActiveClass: 'active'
});

export default router;